var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Gửi lời chúc mừng sinh nhật học sinh",
        visible: _vm.dialogVisible,
        width: "60%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "2vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "table-content row-data" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v("Nội dung :"),
            ]),
            _c("el-input", {
              staticStyle: { "margin-bottom": "20px" },
              attrs: {
                "refs:imageSelected": "",
                type: "textarea",
                rows: 4,
                maxlength: "3000",
                "show-word-limit": "",
                placeholder: "Nhập nội dung lời chúc",
              },
              model: {
                value: _vm.imageSelected.sendContent,
                callback: function ($$v) {
                  _vm.$set(_vm.imageSelected, "sendContent", $$v)
                },
                expression: "imageSelected.sendContent",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "reply" }, [
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "18px",
                "margin-left": "15px",
                "font-family": "arial",
                "margin-bottom": "10px",
              },
            },
            [_vm._v("Chọn thiệp đính kèm :")]
          ),
          _c(
            "div",
            { staticStyle: { "max-height": "500px", overflow: "scroll" } },
            [
              _c("vue-select-image", {
                staticClass: "VueSelectImage",
                attrs: { dataImages: _vm.dataImages, activeClass: "--active" },
                on: { onselectimage: _vm.onSelectImage },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "btt" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "danger", icon: "el-icon-circle-close" },
                on: {
                  click: function ($event) {
                    return _vm.closeDialogByButton("imageSelected")
                  },
                },
              },
              [_vm._v("Đóng")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "success", icon: "el-icon-circle-check" },
                on: {
                  click: function ($event) {
                    return _vm.submitForm("imageSelected")
                  },
                },
              },
              [_vm._v("Gửi")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }