import { render, staticRenderFns } from "./KidsBirthday.vue?vue&type=template&id=45cb37af&scoped=true"
import script from "./KidsBirthday.vue?vue&type=script&lang=js"
export * from "./KidsBirthday.vue?vue&type=script&lang=js"
import style0 from "./KidsBirthday.vue?vue&type=style&index=0&id=45cb37af&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45cb37af",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/SpMFxzSoQ/0/hero/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45cb37af')) {
      api.createRecord('45cb37af', component.options)
    } else {
      api.reload('45cb37af', component.options)
    }
    module.hot.accept("./KidsBirthday.vue?vue&type=template&id=45cb37af&scoped=true", function () {
      api.rerender('45cb37af', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/birthdaymanagement/KidsBirthday.vue"
export default component.exports